import Container from 'react-bootstrap/Container';
import React from 'react';
import styles from './Title.module.scss';

type TitleProps = {
  rightChildren?: string;
};

const Title: React.FC<TitleProps> = ({ children, rightChildren }) => (
  <Container className={styles.titleContainer}>
    <h1 className={styles.title}>{children}</h1>
    <div className={styles.rightChildren}>{rightChildren}</div>
  </Container>
);

export default Title;
