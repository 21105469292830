import React from 'react';
import Spinner from 'react-bootstrap/esm/Spinner';
import styles from './CustomSpinner.module.scss';

const CustomSpinner = () => {
  return (
    <div className={styles.container}>
      <Spinner animation="border" className={styles.spinner} />
    </div>
  );
};

export default CustomSpinner;
