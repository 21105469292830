/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Formik } from 'formik';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import EditProfileFormSchema from './EditProfileFormSchema';
import CustomFormControl from '../CustomFormControl';
import CheckBox from '../CheckBox';
import ErrorBox from '../ErrorBox';
import { AppState, Biobank } from '../../typings/types';
import hasServerError from '../../helpers/serverErrorHelper';
import biobankTranslationKeyByCode from '../../helpers/biobankTranslationKeyMapper';
import { formatConsentedBiobanks } from '../../helpers/consentedBiobanksHelper';
import useEditProfile from '../../hooks/useEditProfile';
import {
  updateUserProfile,
  userUpdateProfileFailure,
} from '../../actions/users';
import LoadingWrapper from '../LoadingWrapper';
import LoadingIndicator from '../LoadingIndicator';

type BiobanksProps = {
  biobanks: Biobank[];
};
type BiobankCheckBoxProps = {
  key?: string;
  label?: string;
  name: string;
  value: string;
  forceChecked: boolean;
};
type BiobankConsentFormControlProps = {
  name: string;
  type: string;
  label: string;
};
const BiobankConsentFormControl: React.FC<BiobankConsentFormControlProps> = (
  props
) => <CustomFormControl {...props} validationSchema={EditProfileFormSchema} />;
const BiobankCheckBox: React.FC<BiobankCheckBoxProps> = (props) => (
  <CheckBox {...props} validationSchema={EditProfileFormSchema} />
);

const Biobanks: React.FC<BiobanksProps> = ({ biobanks }) => {
  const { t } = useTranslation();

  return (
    <Row>
      <Col lg={12}>
        <p className="top-margin">{t('biobank_consent_subheader')}</p>
        {biobanks.map((biobank: Biobank) => (
          <BiobankCheckBox
            key={biobank.biobankid}
            label={t(biobankTranslationKeyByCode[biobank.biobankid])}
            name="biobanks"
            value={biobank.biobankid}
            forceChecked={false}
          />
        ))}
      </Col>
    </Row>
  );
};

const EditProfileForm: React.FC = () => {
  const { t } = useTranslation();
  const { uuid } = useSelector((state: AppState) => state.user.data);
  const { profileData, updateProfile } = useEditProfile(uuid);
  const user = profileData.data;
  const { errorCode, success } = useSelector(
    (state: AppState) => state.forms.updateProfile
  );
  const dispatch = useDispatch();

  const handleConsent = (values): void => {
    const { email, phone } = values;
    let biobanks;
    if (!email && !phone) {
      biobanks = [];
    } else {
      biobanks = values.biobanks;
    }

    const consentedBiobanks = formatConsentedBiobanks(user.biobanks, biobanks);
    const valuesToSave = { email, phone, ...consentedBiobanks };
    const onSuccessfulUpdate = (): void => {
      dispatch(updateUserProfile(biobanks.length > 0));
    };
    const onUpdateFailure = (err): void => {
      dispatch(userUpdateProfileFailure(err.code, err.message));
    };
    updateProfile(valuesToSave, onSuccessfulUpdate, onUpdateFailure);
  };

  const biobankids = user?.biobanks
    ?.filter((biobank: Biobank) => biobank.consented === '1')
    .map((biobank: Biobank) => biobank.biobankid);

  return (
    <LoadingWrapper
      isLoading={profileData.loading}
      isInErrorState={profileData.error != null}
      errorMsg={t('biobank_consent_user_data_loading_error')}
      loadingComponent={<LoadingIndicator />}
    >
      {user ? (
        <Formik
          initialValues={{
            email: user.email,
            phone: user.phone,
            biobanks: biobankids,
          }}
          onSubmit={handleConsent}
          validationSchema={EditProfileFormSchema}
        >
          {({ handleSubmit, isSubmitting }) => (
            <Form onSubmit={handleSubmit as any}>
              {hasServerError(errorCode) && (
                <ErrorBox msg={t(`biobank_consent_form_error_${errorCode}`)} />
              )}
              <Row>
                <Col lg={12}>
                  <BiobankConsentFormControl
                    name="phone"
                    type="tel"
                    label={t('phone')}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <BiobankConsentFormControl
                    name="email"
                    type="email"
                    label={t('email')}
                  />
                </Col>
              </Row>

              <Biobanks biobanks={user.biobanks} />

              <Row>
                <Col lg={8}>
                  {!success ? (
                    <button
                      type="button"
                      className="button top-margin"
                      disabled={isSubmitting}
                      onClick={() => {
                        handleSubmit();
                      }}
                    >
                      {t('edit_profile_save')}
                    </button>
                  ) : (
                    <span>{t('biobank_consent_form_success_message')}</span>
                  )}
                </Col>
              </Row>
              <Row>
                <Col lg={8}>
                  <Link
                    to="/studies"
                    className="button top-margin inline-block text-center"
                  >
                    {t('edit_profile_return')}
                  </Link>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      ) : null}
    </LoadingWrapper>
  );
};

export default EditProfileForm;
