import config from '../config';
import axios from '../helpers/axios';
import logError from '../helpers/logger';

const useUpdateMetadata = () => {
  const updateMetadata = async (surveyid, uuid, field): Promise<void> => {
    try {
      const formData = new FormData();
      Object.entries(field).forEach(([key, value]) => {
        formData.append(key, value as string);
      });
      await axios.post(
        `${config.surveyApiUrl}/survey/${surveyid}/subject/${uuid}`,
        formData,
        {
          withCredentials: true,
        }
      );
    } catch (error) {
      logError(error);
    }
  };
  return updateMetadata;
};
export default useUpdateMetadata;
