import React from 'react';
import { useTranslation } from 'react-i18next';
import BiobankConsentForm from '../components/BiobankConsentForm/BiobankConsentForm';
import Title from '../components/Title/Title';
import MainContainer from '../components/MainContainer';

const BiobankConsentPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <MainContainer>
      <Title>{t('biobank_consent_header')}</Title>
      <div className="finbb-container">
        <div className="narrow-container">
          <p>{t('biobank_consent_introduction')}</p>
          <p>{t('biobank_consent_description')}</p>
          <BiobankConsentForm />
        </div>
      </div>
    </MainContainer>
  );
};

export default BiobankConsentPage;
