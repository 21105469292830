/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { replace } from 'connected-react-router';
import { login } from '../actions/users';

const Auth = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(login());
    dispatch(replace('/'));
  }, []);
  return <></>;
};

export default Auth;
