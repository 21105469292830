/* eslint-disable @typescript-eslint/no-unused-vars */
import USER_LOGOUT from './root.actions';
import { Action } from '../typings/types';
import axios from '../helpers/axios';
import config from '../config';
import logError from '../helpers/logger';
import { removeToken, redirectToLogin } from '../helpers/session';

const userLogout = (): Action => ({ type: USER_LOGOUT });

const logout = (uuid: string) => {
  return async (dispatch): Promise<void> => {
    dispatch(userLogout());
    try {
      if (uuid) {
        const response = await axios.post(
          `${config.surveyApiUrl}/subject/${uuid}/logout`
        );
        if (response.status === 200) {
          removeToken();
          if (config.appEnv !== 'development') {
            window.location.href = `${config.authenticationApiUrl}/logout`;
          } else {
            redirectToLogin();
          }
        }
      } else {
        removeToken();
        redirectToLogin();
      }
    } catch (err) {
      logError(err);
    }
  };
};

export default logout;
