/* eslint-disable no-console */
import * as Sentry from '@sentry/react';
import config from '../config';

const logError = (error: any) => {
  if (config.appEnv !== 'development') {
    if (!error.isAxiosError) {
      console.error(error);
      Sentry.captureException(error);
    }
    return;
  }
  console.log(
    'NOTE: The following error will not be visible on the production environment'
  );
  console.error(error);
};

export default logError;
