const biobankTranslationKeyByCode = {
  10: 'auria_biobank',
  20: 'helsinki_biobank',
  30: 'biobank_of_eastern_finland',
  40: 'central_finland_biobank',
  50: 'northern_finland_biobank_borealis',
  60: 'tampere_biobank',
  70: 'finbb',
  80: 'thl_biobank',
};
export default biobankTranslationKeyByCode;
