/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Container from 'react-bootstrap/Container';
import useFetchSurvey from '../hooks/useFetchSurvey';
import LoadingWrapper from '../components/LoadingWrapper';
import LoadingIndicator from '../components/LoadingIndicator';
import MainContainer from '../components/MainContainer';
import SurveyIntro from '../components/SurveyIntro';

const SurveyIntroductionPage: React.FC = () => {
  type ParamTypes = {
    surveyid: string;
  };
  const { surveyid } = useParams<ParamTypes>();
  const { t } = useTranslation();
  const { survey, loading, error } = useFetchSurvey(surveyid);

  return (
    <MainContainer>
      <LoadingWrapper
        isLoading={loading}
        isInErrorState={error != null}
        errorMsg={t('survey_loading_error')}
        loadingComponent={
          <Container>
            <LoadingIndicator />
          </Container>
        }
      >
        <SurveyIntro survey={survey!} />
      </LoadingWrapper>
    </MainContainer>
  );
};

export default SurveyIntroductionPage;
