import React from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Container from 'react-bootstrap/Container';
import useFetchStudy from '../hooks/useFetchStudy';
import LoadingWrapper from '../components/LoadingWrapper';
import LoadingIndicator from '../components/LoadingIndicator';
import MainContainer from '../components/MainContainer';
import StudyIntro from '../components/StudyIntro/StudyIntro';
import Title from '../components/Title/Title';

const StudyIntroductionPage: React.FC = () => {
  type ParamTypes = {
    studyid: string;
  };
  const { studyid } = useParams<ParamTypes>();
  const { t } = useTranslation();
  const { study, loading, error } = useFetchStudy(studyid);

  return (
    <MainContainer>
      <LoadingWrapper
        isLoading={loading}
        isInErrorState={error != null}
        errorMsg={t('study_intro_loading_error')}
        loadingComponent={
          <Container>
            <LoadingIndicator />
          </Container>
        }
      >
        <Title>{study?.title}</Title>
        <StudyIntro study={study} />
      </LoadingWrapper>
    </MainContainer>
  );
};

export default StudyIntroductionPage;
