import CHANGE_LANGUAGE from '../actions/i18n.actions';
import config from '../config';

type Action = {
  type: string;
  lng: string;
};

const initialState: string = config.defaultLanguage;

const lng = (state = initialState, action: Action) => {
  switch (action.type) {
    case CHANGE_LANGUAGE:
      return action.lng;
    default:
      return state;
  }
};

export default lng;
