import ContentLoader from 'react-content-loader';
import React from 'react';

const LoadingIndicator = () => (
  <ContentLoader
    speed={2}
    viewBox="0 0 1200 300"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    className="loading-indicator"
  >
    <rect x="0" y="0" rx="0" ry="0" width="1235" height="99" />
    <rect x="0" y="110" rx="0" ry="0" width="1235" height="99" />
  </ContentLoader>
);

export default LoadingIndicator;
