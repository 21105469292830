import React from 'react';
import { isIE } from 'react-device-detect';
import IEFallback from './IEFallback';

const withIEFallback = <P extends Record<string, unknown>>(
  WrappedComponent: React.ComponentType<P>
) => {
  return (props: P) => {
    if (isIE) return <IEFallback />;

    return <WrappedComponent {...props} />;
  };
};

export default withIEFallback;
