import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import MainContainer from '../components/MainContainer';
import Login from '../components/Login/Login';
import UserHeader from '../components/User/UserHeader';
import notify from '../helpers/failnotification';

const LoginPage: React.FC = () => {
  const { t } = useTranslation();
  const { search } = useLocation();
  if (search.includes('failed')) {
    setTimeout(() => {
      notify('authentication_failed_title', 'authentication_failed_message');
    }, 500);
  }
  return (
    <MainContainer>
      <div className="App finbb-container">
        <UserHeader title={t('login_page_title')} />
        <Login />
      </div>
    </MainContainer>
  );
};

export default LoginPage;
