/* eslint-disable @typescript-eslint/no-empty-function */
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from '../helpers/axios';
import { toError } from '../helpers/fetchHelper';
import logError from '../helpers/logger';

const initialState = null;

const useFetch = (url: string) => {
  const [error, setError] = useState<null | { error: any }>(null);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<null | any>(initialState);
  const [refreshCount, setRefreshCount] = useState(0);
  const { i18n } = useTranslation();
  const lng = i18n.language;

  const refresh = () => {
    setRefreshCount(refreshCount + 1);
  };

  useEffect(() => {
    const fetchRequest = async () => {
      setLoading(true);
      try {
        const response = await axios.get(url);

        if (response.status === 200) {
          setData(response.data.data);
        } else {
          setData(initialState);
          setError({
            error: response.data.message,
          });
        }
      } catch (e: any) {
        logError(e);
        setData(initialState);
        setError(toError(e));
      } finally {
        setLoading(false);
      }
    };
    fetchRequest();
    return () => {};
  }, [url, refreshCount, lng]);

  return {
    data,
    loading,
    error,
    refresh,
  };
};

export default useFetch;
