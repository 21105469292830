/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */

export default {
  surveyApiUrl: window._env_
    ? window._env_.REACT_APP_SURVEY_API_URL
    : process.env.REACT_APP_SURVEY_API_URL,
  enableJwtAuth: window._env_ ? window._env_.REACT_APP_ENABLE_JWT_AUTH : 1,
  cookieDomain: window._env_
    ? window._env_.REACT_APP_COOKIE_DOMAIN
    : process.env.REACT_APP_COOKIE_DOMAIN,
  cookiePath: window._env_
    ? window._env_.REACT_APP_COOKIE_PATH
    : process.env.REACT_APP_COOKIE_PATH,
  secureCookies: window._env_
    ? window._env_.REACT_APP_SECURE_COOKIES
    : process.env.REACT_APP_SECURE_COOKIES,
  defaultLanguage: window._env_
    ? window._env_.REACT_APP_DEFAULT_LANGUAGE
    : process.env.REACT_APP_DEFAULT_LANGUAGE,
  appEnv: window._env_ ? window._env_.REACT_APP_ENV : process.env.NODE_ENV,
  sentryDSN: window._env_
    ? window._env_.REACT_APP_SENTRY_DSN
    : process.env.REACT_APP_SENTRY_DSN,
  devToken: window._env_
    ? window._env_.REACT_APP_DEV_TOKEN
    : process.env.REACT_APP_DEV_TOKEN,
  authenticationApiUrl: window._env_
    ? window._env_.REACT_APP_AUTH_URL
    : process.env.REACT_APP_AUTH_URL,
  i18nDebug: window._env_
    ? window._env_.REACT_APP_I18N_DEBUG
    : process.env.REACT_APP_I18N_DEBUG,
};
