/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unused-vars */
import React, { useRef, useState, RefObject } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useOnClickOutside from 'use-onclickoutside';
import classNames from 'classnames';
import { AiOutlineUser } from 'react-icons/ai';
import styles from './NavigationUser.module.scss';
import { AppState } from '../../typings/types';

type NavigationUserDropdownProps = {
  active: boolean;
  toggle: (active: boolean) => void;
};

const NavigationUserDropdown: React.FC<NavigationUserDropdownProps> = ({
  active,
  toggle,
}) => {
  const { t } = useTranslation();

  const dropdownClasses = classNames(styles.navigationUserDropdown, {
    [styles.navigationUserDropdownVisible]: active,
  });

  return (
    <ul className={dropdownClasses}>
      <li>
        <NavLink to="/edit-profile" onClick={(): void => toggle(false)}>
          {t('navigation_dropdown_edit_profile')}
        </NavLink>
      </li>
      <li>
        <NavLink to="/logout" onClick={(): void => toggle(false)}>
          {t('navigation_dropdown_logout')}
        </NavLink>
      </li>
    </ul>
  );
};

const NavigationUser: React.FC<React.HTMLAttributes<HTMLDivElement>> = () => {
  const userName = useSelector((state: AppState) => state.user.data.name);

  const UserDisplay = () => (
    <>
      <div className={styles.nameDesktop}>{userName || <AiOutlineUser />}</div>
      <div className={styles.nameMobile}>
        <AiOutlineUser />
      </div>
    </>
  );

  // handle clicking outside menu collapse stuff
  const divRef = useRef() as RefObject<HTMLDivElement>;
  const buttonRef = useRef() as RefObject<HTMLDivElement>;
  const [menuActive, setMenuActive] = useState(false);
  useOnClickOutside(divRef, (e) => {
    if (buttonRef.current !== e.target) {
      setMenuActive(false);
    }
  });
  return (
    <div className={`${styles.navigationUserContainer} nav-link`}>
      <div
        className={styles.navigationUser}
        ref={buttonRef}
        onClick={(): void => setMenuActive(!menuActive)}
      >
        <UserDisplay />
      </div>
      <div ref={divRef}>
        <NavigationUserDropdown active={menuActive} toggle={setMenuActive} />
      </div>
    </div>
  );
};

export default NavigationUser;
