/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-danger */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import ToAppIfLoggedIn from '../ToAppIfLoggedIn';
import { privacyPolicyUrlPreLogin } from '../../pages/static/PrivacyPolicy';
import { termsAndConditionsUrlPreLogin } from '../../pages/static/TermsAndConditions';
import config from '../../config';
import styles from './Login.module.scss';
import { isAuthenticated, setToken } from '../../helpers/session';
import { login } from '../../actions/users';
import { AppState } from '../../typings/types';

const LoginDisclaimer: React.FC = () => {
  const { t } = useTranslation();

  return (
    <p
      className="form-text"
      dangerouslySetInnerHTML={{
        __html: t('login_form_disclaimer', {
          termsUrl: termsAndConditionsUrlPreLogin,
          privacyUrl: privacyPolicyUrlPreLogin,
        }),
      }}
    />
  );
};

const Login: React.FC = () => {
  const { t } = useTranslation();
  const { lng } = useSelector((state: AppState) => state);

  // *** ONLY FOR DEV, TO BE REMOVED BEFORE HANDOFF ***
  const dispatch = useDispatch();
  if (
    config.appEnv === 'development' &&
    !isAuthenticated() &&
    config.devToken
  ) {
    setTimeout(() => {
      setToken(config.devToken);
      dispatch(login());
    }, 5000);
  }
  // **** ****

  return (
    <div className={styles.container}>
      <p>{t('login_page_intro')}</p>
      <a
        href={`${config.authenticationApiUrl}/login?lng=${lng}`}
        className={`${styles.suomifi} button button-block`}
      >
        {t('login_suomifi_redirect')}
      </a>
      <LoginDisclaimer />
    </div>
  );
};

export default ToAppIfLoggedIn(Login);
