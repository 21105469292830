import classNames from 'classnames';
import React from 'react';
import styles from './info-block.module.scss';

type InfoBlockProps = {
  title: string;
  description: string;
  iconSrc: string;
  button: HTMLButtonElement | React.FC | null;
};

const InfoBlock: React.FC<InfoBlockProps> = ({
  title,
  description,
  iconSrc,
  button,
}) => (
  <div className={classNames('container', styles.infoBlock)}>
    <img src={iconSrc} alt={title} className={styles.icon} />
    <h2>{title}</h2>
    <p className={styles.text}>{description}</p>
    {button}
  </div>
);

export default InfoBlock;
