/* eslint-disable react/forbid-prop-types */
import * as Yup from 'yup';
import { validPhone, validEmail } from '../../helpers/formSchemaHelper';

const EditProfileFormSchema = Yup.object().shape({
  phone: validPhone,
  email: validEmail,
  biobanks: Yup.array(),
});

export default EditProfileFormSchema;
