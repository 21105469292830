/* eslint-disable no-unused-vars */
import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { changeLanguage } from '../../actions/i18n';
import styles from './LanguageChange.module.scss';

type LanguageChangeControlProps = {
  language: string;
  toggle: (visible: boolean) => void;
};

const LanguageChangeControl: React.FC<LanguageChangeControlProps> = ({
  language,
  toggle,
}: LanguageChangeControlProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const handleClick = (): void => {
    toggle(false);
    dispatch(changeLanguage(language));
  };
  return (
    <button
      className="language-change-control"
      onClick={handleClick}
      type="button"
    >
      {t(language)}
    </button>
  );
};

type LanguageChangeDropdownProps = {
  active: boolean;
  toggle: (visible: boolean) => void;
};
const LanguageChangeDropdown: React.FC<LanguageChangeDropdownProps> = ({
  active,
  toggle,
}) => {
  const {
    i18n: { language },
  } = useTranslation();

  const dropdownClasses = classNames(styles.languageDropdown, {
    [styles.languageDropdownVisible]: active,
  });

  const languages = ['fi', 'en', 'sv'].filter(
    (lng) => lng !== language.slice(0, 2)
  );

  const LanguageControls = languages.map((lng) => (
    <li key={lng}>
      <LanguageChangeControl language={lng} toggle={toggle} />
    </li>
  ));
  return <ul className={dropdownClasses}>{LanguageControls}</ul>;
};

export default LanguageChangeDropdown;
