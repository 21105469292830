/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Formik, ErrorMessage } from 'formik';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import BiobankConsentFormSchema from './BiobankConsentFormSchema';
import CustomFormControl from '../CustomFormControl';
import CheckBox from '../CheckBox';
import ErrorBox from '../ErrorBox';
import { AppState, Biobank } from '../../typings/types';
import hasServerError from '../../helpers/serverErrorHelper';
import biobankTranslationKeyByCode from '../../helpers/biobankTranslationKeyMapper';
import { formatConsentedBiobanks } from '../../helpers/consentedBiobanksHelper';
import LoadingWrapper from '../LoadingWrapper';
import LoadingIndicator from '../LoadingIndicator';
import useEditProfile from '../../hooks/useEditProfile';
import {
  updateUserProfile,
  userUpdateProfileFailure,
} from '../../actions/users';

type BiobanksProps = {
  biobanks: Biobank[];
};
type BiobankCheckBoxProps = {
  key?: string;
  label?: string;
  name: string;
  value: string;
};
type BiobankConsentFormControlProps = {
  name: string;
  type: string;
  label: string;
};
const BiobankConsentFormControl: React.FC<BiobankConsentFormControlProps> = (
  props
) => (
  <CustomFormControl {...props} validationSchema={BiobankConsentFormSchema} />
);
const BiobankCheckBox: React.FC<BiobankCheckBoxProps> = (props) => (
  <CheckBox {...props} validationSchema={BiobankConsentFormSchema} />
);

const Biobanks: React.FC<BiobanksProps> = ({ biobanks }) => {
  const { t } = useTranslation();

  if (biobanks.length === 1) {
    return (
      <Row>
        <Col lg={12}>
          <p className="top-margin">{t('biobank_consent_subheader')}</p>
          <p>{t(biobankTranslationKeyByCode[biobanks[0].biobankid])}</p>
          <div style={{ display: 'none' }}>
            <BiobankCheckBox name="biobanks" value={biobanks[0].biobankid} />
          </div>
        </Col>
      </Row>
    );
  }

  return (
    <Row>
      <Col lg={12}>
        <p className="top-margin">{t('biobank_consent_subheader')}</p>
        <ul>
          {biobanks.map((biobank: Biobank) => (
            <li key={biobank.biobankid}>
              <span>{t(biobankTranslationKeyByCode[biobank.biobankid])}</span>
              <div style={{ display: 'none' }}>
                <BiobankCheckBox name="biobanks" value={biobank.biobankid} />
              </div>
            </li>
          ))}
        </ul>
      </Col>
    </Row>
  );
};

const BiobankConsentForm: React.FC = () => {
  const { t } = useTranslation();
  const { uuid } = useSelector((state: AppState) => state.user.data);
  const { profileData, updateProfile } = useEditProfile(uuid);
  const user = profileData.data;
  const { errorCode, success } = useSelector(
    (state: AppState) => state.forms.updateProfile
  );
  const dispatch = useDispatch();

  const handleConsent = (values): void => {
    const { email, phone, biobanks } = values;
    const consentedBiobanks = formatConsentedBiobanks(user.biobanks, biobanks);
    const valuesToSave = { email, phone, ...consentedBiobanks };
    const onSuccessfulUpdate = (): void => {
      dispatch(updateUserProfile(biobanks.length > 0));
    };
    const onUpdateFailure = (err): void => {
      dispatch(userUpdateProfileFailure(err.code, err.message));
    };
    updateProfile(valuesToSave, onSuccessfulUpdate, onUpdateFailure);
  };

  const biobankids = user?.biobanks?.map(
    (biobank: Biobank) => biobank.biobankid
  );

  return (
    <LoadingWrapper
      isLoading={profileData.loading}
      isInErrorState={profileData.error != null}
      errorMsg={t('biobank_consent_user_data_loading_error')}
      loadingComponent={<LoadingIndicator />}
    >
      {user ? (
        <Formik
          initialValues={{
            email: user.email || '',
            phone: user.phone || '',
            biobanks: biobankids,
          }}
          onSubmit={handleConsent}
          validationSchema={BiobankConsentFormSchema}
        >
          {({ errors, handleSubmit, isSubmitting }) => (
            <Form onSubmit={handleSubmit as any}>
              {hasServerError(errorCode) && (
                <ErrorBox msg={t(`biobank_consent_form_error_${errorCode}`)} />
              )}
              <Row>
                <Col lg={12}>
                  <BiobankConsentFormControl
                    name="phone"
                    type="tel"
                    label={t('phone')}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <BiobankConsentFormControl
                    name="email"
                    type="email"
                    label={t('email')}
                  />
                </Col>
              </Row>
              {errors &&
                errors.phone &&
                errors.phone === 'biobank_consent_form_error_message' &&
                errors.email &&
                errors.email === 'biobank_consent_form_error_message' && (
                  <ErrorMessage name="">
                    {() => (
                      <ErrorBox
                        className="top-margin"
                        msg={t('biobank_consent_form_error_message')}
                      />
                    )}
                  </ErrorMessage>
                )}

              <Biobanks biobanks={user.biobanks} />

              <Row>
                <Col lg={8}>
                  {!success ? (
                    <button
                      type="button"
                      className="button button-wide top-margin"
                      disabled={isSubmitting}
                      onClick={() => {
                        handleSubmit();
                      }}
                    >
                      {t('biobank_consent_agree')}
                    </button>
                  ) : (
                    <span>{t('biobank_consent_form_success_message')}</span>
                  )}
                </Col>
              </Row>
              <Row>
                <div className="left-margin top-margin">
                  {t('biobank_consent_form_consent_update_message')}
                </div>
              </Row>
              <Row>
                <Col lg={8}>
                  <Link
                    to="/studies"
                    className="button button-wide top-margin inline-block text-center"
                  >
                    {t('biobank_consent_skip')}
                  </Link>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      ) : null}
    </LoadingWrapper>
  );
};

export default BiobankConsentForm;
