import React from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Container from 'react-bootstrap/Container';
import useFetchSurvey from '../hooks/useFetchSurvey';
import LoadingWrapper from '../components/LoadingWrapper';
import LoadingIndicator from '../components/LoadingIndicator';
import Title from '../components/Title/Title';
import MainContainer from '../components/MainContainer';
import InformationReturnForm from '../components/InformationReturnForm';

const InformationReturnFormPage: React.FC = () => {
  type ParamTypes = {
    surveyid: string;
  };
  const { surveyid } = useParams<ParamTypes>();
  const { t } = useTranslation();
  const { survey, loading, error } = useFetchSurvey(surveyid);

  return (
    <MainContainer>
      <LoadingWrapper
        isLoading={loading}
        isInErrorState={error != null}
        errorMsg={t('survey_loading_error')}
        loadingComponent={
          <Container>
            <LoadingIndicator />
          </Container>
        }
      >
        <Title>{t('biobank_info_form_header')}</Title>
        <div className="finbb-container">
          <div className="narrow-container">
            <p>{t('biobank_info_form_description')}</p>
            <InformationReturnForm survey={survey} />
          </div>
        </div>
      </LoadingWrapper>
    </MainContainer>
  );
};

export default InformationReturnFormPage;
