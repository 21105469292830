import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { History } from 'history';
import { reducer as toastrReducer } from 'react-redux-toastr';
import userReducer from '../reducers/user';
import lng from '../reducers/i18n';
import formsReducer from '../reducers/forms';

const portalApp = (history: History) =>
  combineReducers({
    lng,
    router: connectRouter(history),
    user: userReducer,
    forms: formsReducer,
    toastr: toastrReducer,
  });

export default portalApp;
