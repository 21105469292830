/* eslint-disable react/no-danger */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import purify from '../../helpers/domPurifierHelper';

type Study = {
  studyid: string;
  header: string;
  intro: string;
};

type StudyIntroProps = {
  study: Study;
};

const StudyIntro: React.FC<StudyIntroProps> = ({ study }: StudyIntroProps) => {
  const { t } = useTranslation();
  const cleanIntro = purify(study.intro);
  return (
    <div className="finbb-container">
      <div className="wrap-words">
        <div dangerouslySetInnerHTML={{ __html: cleanIntro }} />
        <Link
          className="button button-wide button-link double-vertical-margin"
          to={`/study/${study.studyid}/surveys`}
        >
          {t('study_intro_agree_and_continue')}
        </Link>
        <Link className="link-grey" to="/studies">
          {t('study_intro_back')}
        </Link>
      </div>
    </div>
  );
};

export default StudyIntro;
