export const formatConsentedBiobanks = (userbiobanks, consentedBiobanks) => {
  const formDataBiobanks = userbiobanks.map((biobank) => {
    if (consentedBiobanks.includes(biobank.biobankid)) {
      return { [`biobanks[${biobank.biobankid}]`]: '1' };
    }
    return { [`biobanks[${biobank.biobankid}]`]: '0' };
  });
  const formDataObject = formDataBiobanks.reduce((obj, formBiobank) => {
    return { ...obj, ...formBiobank };
  }, {});
  return formDataObject;
};

export const hasUserGivenConsent = (biobankConsents): boolean => {
  return biobankConsents
    ? biobankConsents.some((biobank) => biobank.consented === '1')
    : false;
};
