/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { RefObject, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useOnClickOutside from 'use-onclickoutside';
import { AiOutlineGlobal } from 'react-icons/ai';
import LanguageChangeDropdown from './LanguageChangeDropdown';
import styles from './LanguageChange.module.scss';

type LanguageChangeControlContainerProps = {
  className: string;
};

const LanguageChangeControlContainer: React.FC<
  LanguageChangeControlContainerProps
> = () => {
  const { t } = useTranslation();
  // handle clicking outside menu collapse stuff
  const divRef = useRef() as RefObject<HTMLDivElement>;
  const buttonRef = useRef() as RefObject<HTMLDivElement>;
  const [menuActive, setMenuActive] = useState(false);
  useOnClickOutside(divRef, (e) => {
    if (buttonRef.current !== e.target) {
      setMenuActive(false);
    }
  });
  return (
    <div className={`${styles.dropdownContainer} nav-link`}>
      <div
        className={styles.language}
        ref={buttonRef}
        onClick={(): void => setMenuActive(!menuActive)}
      >
        <AiOutlineGlobal />
        {t('language')}
      </div>
      <div ref={divRef}>
        <LanguageChangeDropdown active={menuActive} toggle={setMenuActive} />
      </div>
    </div>
  );
};

export default LanguageChangeControlContainer;
