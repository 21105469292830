/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { Provider } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import ReduxToastr from 'react-redux-toastr';
import { PersistGate } from 'redux-persist/integration/react';
import { ConnectedRouter } from 'connected-react-router';
import { Switch } from 'react-router';
import PrivateRoute from '../PrivateRoute';
import configureStore, { history } from '../../store/configureStore';
import styles from './App.module.scss';

import Routes from '../../routes/Routes';

import '../../App.scss';
import Header from '../Header';
import FooterContainer from '../Footer/FooterContainer';
import withIEFallback from '../IEFallback/withIEFallback';
import StaticRoutes from '../../routes/StaticRoutes';

const handleRoute = (props: any) => {
  if (props.render) {
    return props.render;
  }

  return props.private ? (
    <PrivateRoute key={props.path} {...props} />
  ) : (
    <Route key={props.path} {...props} />
  );
};

const routes = [
  ...Routes,
  ...StaticRoutes,
  {
    path: '/',

    exact: true,

    private: false,

    render: <Redirect key="default-redirect-route" to="/login" />,
  },
];

export const { store, persistor } = configureStore();

const App: React.FC = () => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ConnectedRouter history={history}>
        <div>
          <Header />
          <main className={styles.contentWrapper}>
            <Switch>
              {/* Map out all the routes */}
              {routes.map(handleRoute)}
            </Switch>
          </main>
        </div>
        <FooterContainer />
      </ConnectedRouter>
      <ReduxToastr
        timeOut={6000}
        newestOnTop={false}
        preventDuplicates
        position="bottom-center"
        getState={(state) => state.toastr} // This is the default
        transitionIn="fadeIn"
        transitionOut="fadeOut"
        closeOnToastrClick
      />
    </PersistGate>
  </Provider>
);

export default withIEFallback(App);
