import { connect } from 'react-redux';
import Footer from './Footer';

const mapStateToProps = (state: any) => {
  return {
    lng: state.lng,
  };
};

const FooterContainer = connect(mapStateToProps)(Footer);
export default FooterContainer;
