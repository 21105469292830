import React, { useState, ReactNode } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import { Formik } from 'formik';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useTranslation } from 'react-i18next';
import InformationReturnFormSchema from './InformationReturnFormSchema';
import CustomFormControl from '../CustomFormControl';
import useSubmitContactInformation from '../../hooks/useSubmitContactInformation';
import successNotification from '../../helpers/notification';
import errorNotification from '../../helpers/failnotification';
import type { AppState, Survey } from '../../typings/types';

const intialValues = {
  date: new Date().toLocaleDateString('fi'),
  name: '',
  phone: '',
  lang: '',
};

const buttonStyles = ['button', 'button-wide', 'top-margin'];

const supportedLanguages = ['fi', 'sv', 'en'];

type InformationReturnProps = {
  survey: Survey;
};

const InformationReturnForm: React.FC<InformationReturnProps> = ({
  survey,
}: InformationReturnProps) => {
  const { uuid } = useSelector((state: AppState) => state.user.data);
  const { submit } = useSubmitContactInformation(uuid, survey.surveyid);
  const { t } = useTranslation();
  const history = useHistory();
  const lng = useSelector((state: AppState) => state.lng);
  const [confirmed, setConfirmed] = useState(false);

  const submitHandler = (values: typeof intialValues): Promise<void> =>
    submit(
      values,
      () => {
        successNotification('saved', 'updated_profile');
        history.push(`thankyou/${survey.redirect_token}`);
      },
      () =>
        errorNotification(
          'biobank_consent_form_error_500',
          'biobank_consent_user_data_loading_error'
        )
    );

  const declineHandler = (values: typeof intialValues): void => {
    submit(values);
    history.push(`/studies}`);
  };

  const fields: {
    name: string;
    disabled?: boolean;
    type?: 'select';
    options?: ReactNode;
  }[] = [
    { name: 'date', disabled: true },
    { name: 'name' },
    { name: 'phone' },
    {
      name: 'lang',
      type: 'select',
      options: supportedLanguages.map((lang) => (
        <option value={lang} key={lang}>
          {t(lang)}
        </option>
      )),
    },
  ];

  return (
    <Formik
      initialValues={{ ...intialValues, lang: lng }}
      onSubmit={submitHandler}
      validationSchema={InformationReturnFormSchema}
    >
      {({ handleSubmit, isSubmitting }) => (
        <Form onSubmit={handleSubmit as any}>
          <Row>
            <Col lg="12">
              <input
                type="checkbox"
                onChange={() => setConfirmed(!confirmed)}
              />{' '}
              <span className="bold">{t('biobank_info_form_confirm')}</span>
            </Col>
          </Row>
          {fields.map((field) => (
            <Row key={field.name}>
              <Col lg={12}>
                <CustomFormControl
                  name={field.name}
                  label={t(field.name)}
                  validationSchema={InformationReturnFormSchema}
                  inputType={field.type}
                  disabled={field.disabled}
                >
                  {field.options}
                </CustomFormControl>
              </Col>
            </Row>
          ))}
          <Row>
            <Col lg={6}>
              <button
                type="button"
                className={classnames(buttonStyles, 'button-secondary', {
                  disabled: isSubmitting,
                })}
                disabled={isSubmitting}
                onClick={() => declineHandler(intialValues)}
              >
                {t('biobank_info_form_decline')}
              </button>
            </Col>
            <Col lg={6}>
              <button
                type="submit"
                className={classnames(buttonStyles, {
                  disabled: isSubmitting || !confirmed,
                })}
                disabled={isSubmitting || !confirmed}
              >
                {t('biobank_info_form_submit')}
              </button>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};

export default InformationReturnForm;
