import SurveyListPage from '../pages/SurveyListPage';
import LoginPage from '../pages/LoginPage';
import BiobankConsentPage from '../pages/BiobankConsentPage';
import SurveyIntroductionPage from '../pages/SurveyIntroductionPage';
import ThankYouPage from '../pages/ThankYouPage';
import Auth from '../components/Auth';
import StudyListPage from '../pages/StudyListPage';
import StudyIntroductionPage from '../pages/StudyIntroductionPage';
import LogoutPage from '../pages/LogoutPage';
import EditProfilePage from '../pages/EditProfilePage';
import FailedLoginPage from '../pages/FailedLoginPage';
import RedirectBasedOnConsent from '../components/RedirectBasedOnConsent';
import InformationReturnFormPage from '../pages/InformationReturnFormPage';

export default [
  {
    path: '/login',
    exact: true,
    private: false,
    component: LoginPage,
  },
  { path: '/logout', private: false, exact: true, component: LogoutPage },
  {
    path: '/auth',
    exact: true,
    private: false,
    component: Auth,
  },
  {
    path: '/failedauth',
    private: false,
    exact: true,
    component: FailedLoginPage,
  },
  {
    path: '/',
    private: true,
    exact: true,
    component: RedirectBasedOnConsent,
  },
  {
    path: '/consent',
    private: true,
    exact: true,
    component: BiobankConsentPage,
  },
  {
    path: '/studies',
    private: true,
    exact: true,
    component: StudyListPage,
  },
  {
    path: '/study/:studyid/introduction',
    private: true,
    exact: true,
    component: StudyIntroductionPage,
  },
  {
    path: '/study/:studyid/surveys',
    private: true,
    exact: true,
    component: SurveyListPage,
  },
  {
    path: '/study/:studyid/survey/:surveyid/introduction',
    private: true,
    exact: true,
    component: SurveyIntroductionPage,
  },
  {
    path: '/study/:studyid/survey/:surveyid/form',
    private: true,
    exact: true,
    component: InformationReturnFormPage,
  },
  {
    path: '/study/:studyid/survey/:surveyid/thankyou/:token',
    private: true,
    exact: true,
    component: ThankYouPage,
  },
  {
    path: '/edit-profile',
    private: true,
    exact: true,
    component: EditProfilePage,
  },
];
