import config from '../config';
import axios from '../helpers/axios';
import logError from '../helpers/logger';
import useUser from './useUser';

const useEditProfile = (userid: string) => {
  const { data, loading, error } = useUser(userid);

  const updateProfile = async (values, onSuccess, onError): Promise<void> => {
    try {
      const formData = new FormData();
      Object.entries(values).forEach(([key, value]) => {
        formData.append(key, value as string);
      });

      const response = await axios.post(
        `${config.surveyApiUrl}/subject/${userid}`,
        formData,
        {
          withCredentials: true,
        }
      );
      if (response.status === 200) {
        onSuccess();
      }
    } catch (err: any) {
      logError(err);
      if (err.response) {
        onError({
          code: err.response.status,
          message: err.response.data.message,
        });
      } else {
        onError(err);
      }
    }
  };

  return {
    profileData: {
      data,
      loading,
      error,
    },
    updateProfile,
  };
};

export default useEditProfile;
