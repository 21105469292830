import * as Yup from 'yup';

const InformationReturnFormSchema = Yup.object().shape({
  name: Yup.string().trim().required('required_field'),
  phone: Yup.string()
    .matches(/^\+?([0-9 ]){6,15}$/, 'phone_error_message')
    .required('required_field'),
  lang: Yup.string().min(2).max(2).required('required_field'),
});

export default InformationReturnFormSchema;
