import { History } from 'history';
import { CombinedState } from 'redux';
import App from '../store/App';
import USER_LOGOUT from '../actions/root.actions';

type Action = {
  type: string;
};

const rootReducer =
  (history: History) => (state: CombinedState<any>, action: Action) => {
    const app = App(history);

    if (action.type === USER_LOGOUT) {
      return app(undefined, action);
    }
    return app(state, action);
  };

export default rootReducer;
