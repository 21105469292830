import {
  USER_LOGIN_SUCCESSFUL,
  USER_LOGIN_FAILURE,
  USER_UPDATE_PROFILE_SUCCESSFUL,
} from '../actions/users.actions';
import { InitialUserState, UserState } from '../typings/types';

const initialState: InitialUserState = {
  token: null,
  data: {},
};

const userReducer = (
  state = initialState,
  action: any
): UserState | InitialUserState => {
  switch (action.type) {
    case USER_LOGIN_SUCCESSFUL:
      return {
        token: action.token,
        data: action.data,
      };
    case USER_LOGIN_FAILURE:
      return initialState;
    case USER_UPDATE_PROFILE_SUCCESSFUL:
      return {
        ...state,
        data: {
          ...state.data,
          consented: action.data,
        },
      };
    default:
      return state;
  }
};

export default userReducer;
