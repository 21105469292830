import { applyMiddleware, createStore } from 'redux';
import { createBrowserHistory } from 'history';
import { composeWithDevTools } from 'redux-devtools-extension';
import { routerMiddleware } from 'connected-react-router';
import freezeMiddleware from 'redux-freeze';
import thunk from 'redux-thunk';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import rootReducer from '../reducers/rootReducer';

const persistConfig = {
  key: 'biobankportal',
  storage,
  whitelist: ['lng', 'user'],
};

export const history = createBrowserHistory();

const persistedReducer = persistReducer(persistConfig, rootReducer(history));

const configureStore = () => {
  const store = createStore(
    persistedReducer,
    composeWithDevTools(
      applyMiddleware(thunk, routerMiddleware(history), freezeMiddleware)
      // other store enhancers if any
    )
  );

  const persistor = persistStore(store);
  return { store, persistor };
};

export default configureStore;
