import React from 'react';
import { useTranslation } from 'react-i18next';
import SurveyList from '../components/SurveyList/SurveyList';
import Title from '../components/Title/Title';
import MainContainer from '../components/MainContainer';

const SurveyListPage = () => {
  const { t } = useTranslation();

  return (
    <MainContainer>
      <Title>{t('surveys')}</Title>
      <SurveyList />
    </MainContainer>
  );
};

export default SurveyListPage;
