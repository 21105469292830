import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import useFetchStudies from '../../hooks/useFetchStudies';
import LoadingWrapper from '../LoadingWrapper';
import LoadingIndicator from '../LoadingIndicator';
import styles from './StudyList.module.scss';
import EmptyListPlaceholder from '../EmptyListPlaceholder';

type Study = {
  title: string;
  description?: string;
  studyid: string;
  type: string;
  surveys: string[];
};

type StudyProps = {
  study: Study;
};
type StudiesProps = {
  studies: Study[];
  placeholderText: string;
};

const Study: React.FC<StudyProps> = ({ study }: StudyProps) => {
  const { t } = useTranslation();

  const isSurvey = !(study.type === 'readonly' && study.surveys.length === 1);
  const localizationKey = isSurvey
    ? 'studylist_start_study'
    : 'studylist_start_tiedonpalautus';
  const link = isSurvey
    ? `/study/${study.studyid}/introduction`
    : `/study/${study.studyid}/survey/${study.surveys[0]}/introduction`;

  return (
    <Card className={styles.card} style={{ width: '20em' }}>
      <Card.Body>
        <Card.Title>{study.title}</Card.Title>
        <Card.Text>{study.description}</Card.Text>
      </Card.Body>
      <Card.Footer className={styles.cardFooter}>
        <Link className="button button-block button-link" to={link}>
          {t(localizationKey)}
        </Link>
      </Card.Footer>
    </Card>
  );
};

const Studies: React.FC<StudiesProps> = ({ studies, placeholderText }) => {
  return (
    <div className={styles.container}>
      {studies && studies.length > 0 ? (
        studies.map((study: Study) => (
          <Study key={study.studyid} study={study} />
        ))
      ) : (
        <EmptyListPlaceholder title={placeholderText} />
      )}
    </div>
  );
};

const StudyList: React.FC = () => {
  const { t } = useTranslation();
  const {
    studies,
    loading: studiesLoading,
    error: studiesError,
  } = useFetchStudies();

  return (
    <div className="finbb-container">
      <LoadingWrapper
        isLoading={studiesLoading}
        isInErrorState={studiesError != null}
        errorMsg={t('studylist_studies_loading_error')}
        loadingComponent={<LoadingIndicator />}
      >
        <Studies
          studies={studies}
          placeholderText={t('studylist_no_studies')}
        />
      </LoadingWrapper>
    </div>
  );
};
export default StudyList;
