import config from '../config';
import useFetch from './useFetch';

const useFetchSurveys = () => {
  const { data, loading, error } = useFetch(`${config.surveyApiUrl}/survey`);

  return {
    surveys: data,
    loading,
    error,
  };
};

export default useFetchSurveys;
