/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-danger */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import useFetchSurvey from '../hooks/useFetchSurvey';
import LoadingWrapper from '../components/LoadingWrapper';
import LoadingIndicator from '../components/LoadingIndicator';
import Title from '../components/Title/Title';
import MainContainer from '../components/MainContainer';
import { AppState, Survey } from '../typings/types';
import purify from '../helpers/domPurifierHelper';
import useUpdateMetadata from '../hooks/useUpdateMetadata';

type ThankYouProps = {
  survey: Survey;
};

const ThankYou: React.FC<ThankYouProps> = ({ survey }: ThankYouProps) => {
  const { t } = useTranslation();
  const cleanthankyou = purify(survey.thankyou);
  const isSurvey = survey.type === 'answearable';
  const link = isSurvey ? `/study/${survey.studyid}/surveys` : `/study`;
  const linkText = isSurvey
    ? 'survey_intro_back'
    : 'survey_intro_back_to_start';

  return (
    <div className="finbb-container">
      <div className="narrow-container">
        <div dangerouslySetInnerHTML={{ __html: cleanthankyou }} />
        <Link
          className="button button-wide button-link double-vertical-margin"
          to={link}
        >
          {t(linkText)}
        </Link>
      </div>
    </div>
  );
};

const ThankYouPage: React.FC = () => {
  type ParamTypes = {
    surveyid: string;
    token: string;
  };
  const { surveyid, token } = useParams<ParamTypes>();
  const { survey, loading, error } = useFetchSurvey(surveyid);
  const { t } = useTranslation();
  const { uuid } = useSelector((state: AppState) => state.user.data);
  const updateMetadata = useUpdateMetadata();

  useEffect(() => {
    const updateMetadataIfTokenValid = () => {
      if (survey && token === survey.redirect_token) {
        updateMetadata(surveyid, uuid, { participated: 1 });
      }
    };
    updateMetadataIfTokenValid();
  }, [survey]);

  return (
    <MainContainer>
      <LoadingWrapper
        isLoading={loading}
        isInErrorState={error != null}
        errorMsg={t('survey_loading_error')}
        loadingComponent={<LoadingIndicator />}
      >
        <Title>{t('thank_you')}</Title>
        <ThankYou survey={survey} />
      </LoadingWrapper>
    </MainContainer>
  );
};

export default ThankYouPage;
