import config from '../config';
import useFetch from './useFetch';

const useFetchSurvey = (id: string) => {
  const { data, loading, error } = useFetch(
    `${config.surveyApiUrl}/survey/${id}`
  );

  return {
    survey: data,
    loading,
    error,
  };
};

export default useFetchSurvey;
