import React from 'react';
import { useDispatch } from 'react-redux';
import { redirectToLogin } from '../helpers/session';
import { userLoginFailure } from '../actions/users';

const FailedLoginPage = () => {
  const dispatch = useDispatch();
  dispatch(userLoginFailure(403, 'Authentication failed'));
  redirectToLogin('/login?failed');
  return <></>;
};

export default FailedLoginPage;
