/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */
import axios from 'axios';
import i18next from 'i18next';
import { removeToken, redirectToLogin, getToken } from './session';
import configureStore from '../store/configureStore';
import USER_LOGOUT from '../actions/root.actions';
import notify from './failnotification';

const { store } = configureStore();

const userLogout = () => ({ type: USER_LOGOUT });

const notifyTokenExpired = (): void => notify('expired', 'general_error_401');

axios.interceptors.request.use(
  (config) => {
    if (!config.headers) {
      config.headers = {};
    }

    const token = getToken();
    if (token && token.length) {
      config.headers.Authorization = `Bearer ${token}`;
      if (i18next && i18next.language) {
        config.headers['Accept-Language'] = i18next.language;
      }
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (res) => res,
  (error) => {
    if (!error.response) {
      return Promise.reject(error);
    }
    if (error.response.status === 401) {
      notifyTokenExpired();
      setTimeout(() => {
        removeToken();
        store.dispatch(userLogout());
        redirectToLogin();
      }, 5000);
    }
    return Promise.reject(error);
  }
);

export default axios;
