/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const termsAndConditionsUrl = 'static/terms-and-conditions';
export const termsAndConditionsUrlPreLogin = '/terms-and-conditions';

const TermsAndConditions: React.FC = () => {
  const { i18n } = useTranslation();
  const lang = useMemo(() => i18n.language, [i18n.language]);

  const renderSwitch = (lng: string) => {
    switch (lng) {
      case 'en':
        return <TermsEnglish />;
      case 'fi':
        return <TermsFinnish />;
      case 'sv':
        return <TermsSwedish />;
      default:
        return <TermsFinnish />;
    }
  };

  return <div className="finbb-container">{renderSwitch(lang)}</div>;
};

const TermsEnglish: React.FC = () => {
  return (
    <>
      <h1 className="static-h1">Terms of use for the MyBiobank website</h1>

      <p>
        Read these Terms of Use carefully before proceeding to use the MyBiobank
        service (hereinafter the “Service”). Together with the Privacy
        Statement, these Terms of Use constitute a legal agreement between the
        Service user and the Service provider. The service is provided by the
        Finnish Biobank Cooperative – FINBB in collaboration with biobank
        operators (“Service Provider”).
      </p>

      <p>
        The use of the Service requires the acceptance of these Terms of Use.
        The Terms of Use are valid until further notice and subject to change.
        The latest version of the Terms of Use is available for viewing in the
        Service, and any use of the Service after the changes is deemed to
        constitute acceptance of the Terms of Use. If you do not accept these
        terms, you must stop using the Service and leave the site.
      </p>

      <h4>Participation in research projects</h4>

      <p>
        The Service is used for presenting third-party research projects in
        which the user may choose to participate. Participation is entirely
        voluntary, and logging in to the Service will not in any way oblige the
        user to anything.
      </p>

      <h4>Use of the Service</h4>

      <p>
        The purpose of the Service is to provide users with an opportunity to
        familiarise themselves with, and participate in, research projects and
        to give their potential e-service consent to biobank operators. The
        Service may only be used for these purposes and in accordance with these
        Terms of Use. The Service is free of charge for the users.
      </p>

      <p>
        Authentication with the service is carried out via the Suomi.fi
        authentication service. For this purpose, you will need Finnish online
        banking credentials, a certificate card or a mobile certificate.
      </p>

      <h4>Use of cookies</h4>

      <p>
        The Service uses technical cookies, i.e. small text files that are
        stored on the user’s computer. Their use is necessary for the
        functioning of certain parts of the Service, such as user
        authentication.
      </p>

      <h4>Changes</h4>

      <p>
        The Service Provider develops the Service on a continuous basis, due to
        which the Service range and content are subject to change. The Service
        Provider also has the right to terminate the provision of Services.
      </p>
      <p>
        The Service Provider has the right to suspend the provision of the
        Service for the duration of maintenance and update operations, for
        example, or for some other reason.
      </p>
      <h4>Intellectual property rights</h4>
      <p>
        Title and all intellectual property rights to the Service and any text,
        image and other material published in the Service belong to the Service
        Provider or its partners. Any material published in the service may not
        be copied or distributed, except for the research project announcements
        and informed consent forms, which those participating in the research
        projects may print or store for their private use.
      </p>
      <h4>Links to other sites</h4>
      <p>
        The Service contains links to other third-party websites. The Service
        Provider is not responsible for any third-party websites.
      </p>
      <h4>Warranties</h4>
      <p>
        The service is provided on an “as is” and “as available” basis without
        any warranties of any kind.
      </p>
      <h4>Limitations of liability</h4>
      <p>
        The Service Provider is not be liable for any indirect or consequential
        damage. Unless otherwise provided by mandatory law, the Service
        Provider’s liability for direct damage is limited to fifty (50) euros.
        The Service Provider is not liable for any destruction, loss or
        alteration of data or files for any reason whatsoever, or for any loss
        or damage or expenses incurred as a result thereof.
      </p>
      <p>
        The Service Provider is not be liable for any communications and
        informed consent forms concerning the research projects that are made
        available through the Service, or for the execution of the research
        projects. The parties responsible for the research projects will be
        announced in the Service prior to the users’ participation in them.
      </p>
      <h4>Termination</h4>
      <p>
        The Service Provider may, at any time, terminate or restrict the use of
        the Service.
      </p>
      <h4>Other terms and conditions</h4>
      <p>The Privacy Statement is available on the Omabiopankki.fi website.</p>
      <p>
        This Agreement is governed by the law of Finland. Any disputes arising
        out of this agreement shall be settled in arbitration proceedings in
        accordance with the rules of the Arbitration Institute of the Central
        Chamber of Commerce of Finland, in which case the arbitration tribunal
        shall consist of one (1) arbiter. The arbitration proceedings shall take
        place in Helsinki, Finland. The Service Provider shall have the right to
        assign this agreement to a third party.
      </p>
      <h4>Supported operating systems</h4>
      <p>The Service supports the two most recent internet browser versions:</p>
      <ul className="static-list">
        <li>Windows/Mac: Chrome, Firefox, Edge</li>
        <li>iOS (Safari)</li>
        <li>Android</li>
      </ul>
      <h4>Service provider</h4>
      <div className="static-block">
        <div>
          <strong className="static-service-detail">Name:</strong>Finnish
          Biobank Cooperative – FINBB
        </div>
        <div>
          <strong className="static-service-detail">Registered office:</strong>
          Turku, Finland
        </div>
        <div>
          <strong className="static-service-detail">Address:</strong>Ruukinkatu
          2–4, 1st floor, FI-20540 Turku, Finland
        </div>
        <div>
          <strong className="static-service-detail">Business ID:</strong>
          2853268-7
        </div>
      </div>
      <div className="static-block">
        <div className="static-container">
          <div>Auria Biobank</div>
          <a href="https://www.auria.fi/biopankki/index.php">
            www.auria.fi/biopankki/index.php
          </a>
        </div>
        <div className="static-container">
          <div>Helsinki Biobank</div>
          <a href="https://www.helsinginbiopankki.fi">
            www.helsinginbiopankki.fi
          </a>
        </div>
        <div className="static-container">
          <div>Finnish Clinical Biobank Tampere</div>
          <a href="https://www.tays.fi/biopankki">www.tays.fi/biopankki</a>
        </div>
      </div>
    </>
  );
};

const TermsFinnish: React.FC = () => {
  return (
    <>
      <h1 className="static-h1">OmaBiopankki-verkkosivuston käyttöehdot</h1>
      <p>
        Lue nämä käyttöehdot huolellisesti ennen kuin siirryt käyttämään
        OmaBiopankki-palvelua (Palvelu). Nämä ehdot yhdessä tietosuojaselosteen
        kanssa muodostavat oikeudellisen sopimuksen Palvelun käyttäjän ja
        Palvelun tuottajan välillä. Palvelun tuottaa Biopankkien Osuuskunta
        Suomi – FINBB yhteistyössä biopankkitoimijoiden kanssa
        (Palveluntarjoaja).
      </p>
      <p>
        Palvelun käyttö edellyttää näiden käyttöehtojen hyväksymistä.
        Käyttöehdot ovat voimassa toistaiseksi ja ne voivat muuttua. Palvelussa
        on nähtävissä käyttöehtojen viimeisin versio ja muutoksien jälkeen
        tapahtuva Palvelun käyttö katsotaan ehtojen hyväksymiseksi. Mikäli et
        hyväksy näitä ehtoja, sinun tulee lopettaa Palvelun käyttäminen ja
        poistua sivustolta.
      </p>
      <h4>Tutkimushankkeisiin osallistuminen</h4>
      <p>
        Palvelussa esitellään kolmansien osapuolten tutkimushankkeita, joihin
        käyttäjä voi osallistua. Osallistuminen on täysin vapaaehtoista, eikä
        Palveluun kirjautuminen velvoita mihinkään.
      </p>
      <h4>Palvelun käyttö</h4>
      <p>
        Palvelun tarkoitus on mahdollistaa tutkimushankkeisiin tutustuminen ja
        niihin osallistuminen sekä yhteystietojen ja mahdollisen sähköisen
        asioinnin suostumuksen antaminen biopankkitoimijoille. Palvelua saa
        käyttää vain näihin tarkoituksiin ja näiden käyttöehtojen mukaisesti.
        Palvelu on käyttäjille maksuton.
      </p>
      <p>
        Palveluun tunnistautuminen tehdään Suomi.fi-tunnistautumisen kautta.
        Tähän tarvitaan suomalaiset verkkopankkitunnukset, varmennekortti tai
        mobiilivarmenne.
      </p>
      <h4>Evästeiden käyttö</h4>
      <p>
        Palvelussa käytetään teknisiä evästeitä, eli pieniä tekstitiedostoja,
        jotka tallentuvat käyttäjän tietokoneelle. Niiden käyttö on
        välttämätöntä joidenkin Palvelun osien toimivuudelle, kuten käyttäjän
        tunnistautumiselle.
      </p>
      <h4>Muutokset</h4>
      <p>
        Palveluntarjoaja kehittää Palvelua jatkuvasti ja siksi Palvelun
        valikoima ja sisältö voivat muuttua. Palveluntarjoajalla on myös oikeus
        lopettaa Palveluiden tuottaminen.
      </p>
      <p>
        Palveluntarjoajalla on oikeus keskeyttää palvelun tarjoaminen
        esimerkiksi huolto- ja päivitystoimenpiteiden ajaksi tai muusta syystä.
      </p>
      <h4>Immateriaalioikeudet</h4>
      <p>
        Omistus- ja kaikki immateriaalioikeudet Palveluun ja Palvelussa
        julkaistavaan teksti-, kuva- ja muuhun aineistoon kuuluvat
        Palveluntarjoajalle tai tämän yhteistyökumppaneille. Palvelussa
        julkaistavaa aineistoa ei saa kopioida eikä jakaa, lukuun ottamatta
        tutkimushankkeiden tiedotteita ja suostumuksia, jotka tutkimuksiin
        osallistuva voi tulostaa tai tallentaa omaan yksityiseen käyttöönsä.
      </p>
      <h4>Linkit muille sivustoille</h4>
      <p>
        Palvelussa on linkkejä muihin kolmansien osapuolien verkkosivustoihin.
        Palveluntarjoaja ei vastaa kolmansien osapuolien sivustoista.
      </p>
      <h4>Takuut</h4>
      <p>
        Palvelu tarjotaan “sellaisena kun se on” ja “milloin saatavilla”
        -periaatteella ilman takuita.
      </p>
      <h4>Vastuunrajoitukset</h4>
      <p>
        Palveluntarjoaja ei vastaa mistään välillisestä tai epäsuorasta
        vahingosta. Ellei pakottavasta lainsäädännöstä muuta johdu,
        Palveluntarjoajan vastuu välittömästä vahingosta rajoittuu
        viiteenkymmeneen (50) euroon. Palveluntarjoaja ei vastaa mistä syystä
        tahansa tapahtuneesta tietojen tai tiedostojen tuhoutumisesta,
        katoamisesta tai muuttumisesta eikä näistä aiheutuneista vahingoista ja
        kuluista.
      </p>
      <p>
        Palveluntarjoaja ei vastaa Palvelun kautta tarjolla olevia
        tutkimushankkeita koskevista tiedonannoista, suostumuslomakkeista eikä
        tutkimusten toteuttamisesta. Tutkimushankkeista vastaavat tahot
        ilmoitetaan Palvelussa ennen tutkimuksiin osallistumista.
      </p>
      <h4>Irtisanominen</h4>
      <p>
        Palveluntarjoajalla on milloin tahansa oikeus irtisanoa Palvelun käyttö
        tai rajoittaa sitä.
      </p>
      <h4>Muut ehdot</h4>
      <p>Tietosuojaseloste on nähtävissä Omabiopankki.fi -sivustolla.</p>
      <p>
        Tähän sopimukseen sovelletaan Suomen lakia. Tästä sopimuksesta
        mahdollisesti aiheutuvat riitaisuudet ratkaistaan välimiesoikeudessa
        Keskuskauppakamarin välityslautakunnan sääntöjen mukaisessa
        menettelyssä, jolloin välimiesoikeuden muodostaa yksi (1) välimies.
        Välimiesmenettelyn paikka on Helsinki, Suomi. Palveluntarjoajalla on
        oikeus siirtää tämä sopimus kolmannelle osapuolelle.
      </p>
      <h4>Tuetut käyttöympäristöt</h4>
      <p>Palvelu tukee 2 viimeisintä versiota internetselaimista:</p>
      <ul className="static-list">
        <li>Windows/Mac: Chrome, Firefox, Edge</li>
        <li>iOS (Safari)</li>
        <li>Android</li>
      </ul>
      <h4>Palvelun tarjoaja</h4>
      <div className="static-block">
        <div>
          <strong className="static-service-detail">Nimi:</strong>FINBB –
          Biopankkien Osuuskunta Suomi
        </div>
        <div>
          <strong className="static-service-detail">Kotipaikka:</strong>
          Turku
        </div>
        <div>
          <strong className="static-service-detail">Osoite:</strong>Ruukinkatu
          2-4, 1. krs, 20540, Turku, Finland
        </div>
        <div>
          <strong className="static-service-detail">Y-tunnus:</strong>
          2853268-7
        </div>
      </div>
      <div className="static-block">
        <div className="static-container">
          <div>Auria Biopankki</div>
          <a href="https://www.auria.fi/biopankki/index.php">
            www.auria.fi/biopankki/index.php
          </a>
        </div>
        <div className="static-container">
          <div>Helsingin Biopankki</div>
          <a href="https://www.helsinginbiopankki.fi">
            www.helsinginbiopankki.fi
          </a>
        </div>
        <div className="static-container">
          <div>Tampereen Biopankki</div>
          <a href="https://www.tays.fi/biopankki">www.tays.fi/biopankki</a>
        </div>
      </div>
    </>
  );
};

const TermsSwedish: React.FC = () => {
  return (
    <>
      <h1 className="static-h1">Användarvillkor för webbplatsen MinBiobank</h1>
      <p>
        Läs igenom dessa användarvillkor noggrant innan du börjar använda
        MinBiobank-tjänsten (Tjänsten). Dessa villkor bildar tillsammans med
        dataskyddsbeskrivningen ett juridiskt avtal mellan Tjänstens användare
        och Tjänsteproducenten. Tjänsten produceras av Biopankkien Osuuskunta
        Suomi – FINBB i samarbete med biobanksaktörerna (Tjänsteleverantör).
      </p>
      <p>
        Användaren måste godkänna dessa användarvillkor för att kunna använda
        tjänsten. Användarvillkoren gäller tills vidare och det kan komma att
        ske ändringar i dem. Den senaste versionen av användarvillkoren finns i
        tjänsten. Användning av Tjänsten efter att ändringar har gjorts i
        användarvillkoren betraktas som ett godkännande av villkoren. Om du inte
        godkänner dessa villkor, ska du sluta använda Tjänsten och lämna
        webbplatsen.
      </p>
      <h4>Deltagande i forskningsprojekt</h4>
      <p>
        I Tjänsten presenteras forskningsprojekt av tredje part som användaren
        kan delta i. Det är helt frivilligt att delta och inloggning i Tjänsten
        förbinder inte användaren till något.
      </p>
      <h4>Användning av Tjänsten</h4>
      <p>
        Syftet med Tjänsten är att göra det möjligt att bekanta sig med
        forskningsprojekt och delta i dem samt att ge kontaktuppgifter och
        eventuellt samtycke till elektronisk ärendehantering till
        biobanksaktörerna. Tjänsten får endast användas till dessa ändamål och
        enligt dessa användarvillkor. Tjänsten är avgiftsfri för användarna.
      </p>
      <p>
        Identifieringen i Tjänsten görs via Suomi.fi-identifieringen. Till detta
        behövs finländska nätbankskoder, certifikatkort eller mobil-ID.
      </p>
      <h4>Användning av kakor</h4>
      <p>
        I Tjänsten används tekniska kakor (cookies), dvs. små textfiler som
        sparas på användarens dator. Användningen av dem är nödvändig för att
        vissa delar av Tjänsten, såsom användaridentifikationen, ska fungera.
      </p>
      <h4>Ändringar</h4>
      <p>
        Tjänsteleverantören utvecklar Tjänsten kontinuerligt och därmed kan
        urvalet av de tjänster som tillhandahålls och innehållet i dem variera.
        Tjänsteleverantören har även rätt att lägga ner produktionen av
        Tjänsterna.
      </p>
      <p>
        Tjänsteleverantören har rätt att avbryta tillhandahållandet av Tjänsten
        till exempel medan underhålls- och uppdateringsåtgärder utförs eller av
        annan orsak.
      </p>
      <h4>Immaterialrättigheter</h4>
      <p>
        Tjänsten och allt text- och bildmaterial och allt annat material som
        publiceras i Tjänsten samt ägande- och immaterialrätten till dessa ägs
        av Tjänsteleverantören eller dess samarbetspartner. Materialet som
        publiceras i Tjänsten får inte kopieras eller delas, med undantag av
        forskningsprojektens meddelanden och samtycken som den som deltar i
        studierna kan skriva ut eller spara för eget privat bruk.
      </p>
      <h4>Länkar till andra webbplatser</h4>
      <p>
        I Tjänsten finns länkar till andra tredje parters webbplatser.
        Tjänsteleverantören ansvarar inte för dessa tredje parters webbplatser.
      </p>
      <h4>Garantier</h4>
      <p>
        Tjänsten tillhandahålls enligt principen ”som den är” och ”när
        tillgänglig” utan garantier.
      </p>
      <h4>Ansvarsbegränsningar</h4>
      <p>
        Tjänsteleverantören ansvarar inte för några direkta eller indirekta
        skador. Om inget annat föranleds av tvingande lagstiftning, är
        Tjänsteleverantörens ansvar för omedelbar skada begränsat till femtio
        (50) euro. Tjänsteleverantören ansvarar inte för att data eller filer
        förstörs, försvinner eller ändras, oavsett av vilken anledning, eller
        för skador och kostnader till följd av detta.
      </p>
      <p>
        Tjänsteleverantören ansvarar inte för tillkännagivanden eller
        samtyckesblanketter som rör forskningsprojekt och inte för genomförandet
        av studierna. De instanser som ansvarar för forskningsprojekten meddelas
        i Tjänsten före studiedeltagandet.
      </p>
      <h4>Uppsägning</h4>
      <p>
        Tjänsteleverantören har när som helst rätt att säga upp eller begränsa
        användningen av Tjänsten.
      </p>
      <h4>Övriga villkor</h4>
      <p>Dataskyddsbeskrivningen finns på webbplatsen Omabiopankki.fi.</p>
      <p>
        På detta avtal tillämpas Finlands lag. Eventuella tvister som uppstår på
        grund av detta avtal avgörs genom skiljedom i ett förfarande i enlighet
        med Centralhandelskammarens skiljedomsregler, varvid skiljedomstolen
        består av en (1) skiljeman. Skiljeförfarandets säte är Helsingfors,
        Finland. Tjänsteleverantören har rätt att överföra detta avtal till
        tredje part.
      </p>
      <h4>Användningsmiljöer som stöds</h4>
      <p>Tjänsten stöder de 2 senaste versionerna av följande webbläsare:</p>
      <ul className="static-list">
        <li>Windows/Mac: Chrome, Firefox, Edge</li>
        <li>iOS (Safari)</li>
        <li>Android</li>
      </ul>
      <h4>Tjänsteleverantör</h4>
      <div className="static-block">
        <div>
          <strong className="static-service-detail">Namn:</strong>FINBB –
          Biopankkien Osuuskunta Suomi
        </div>
        <div>
          <strong className="static-service-detail">Hemort:</strong>
          Åbo
        </div>
        <div>
          <strong className="static-service-detail">Adress:</strong>Bruksgatan
          2–4, vån. 1, 20540, Åbo, Finland
        </div>
        <div>
          <strong className="static-service-detail">FO-nummer:</strong>
          2853268-7
        </div>
      </div>
      <div className="static-block">
        <div className="static-container">
          <div>Auria Biobank</div>
          <a href="https://www.auria.fi/biopankki/index.php">
            www.auria.fi/biopankki/index.php
          </a>
        </div>
        <div className="static-container">
          <div>Helsingfors Biobank</div>
          <a href="https://www.helsinginbiopankki.fi">
            www.helsinginbiopankki.fi
          </a>
        </div>
        <div className="static-container">
          <div>Tammerfors Biobank</div>
          <a href="https://www.tays.fi/biopankki">www.tays.fi/biopankki</a>
        </div>
      </div>
    </>
  );
};

export default TermsAndConditions;
