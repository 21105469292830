import React from 'react';
import { Redirect } from 'react-router-dom';
import { isAuthenticated } from '../helpers/session';

const ToAppIfLoggedIn = <P extends Record<string, unknown>>(
  WrappedComponent: React.ComponentType<P>
) => {
  return (props: P) => {
    if (isAuthenticated()) {
      return <Redirect to="/" />;
    }
    return <WrappedComponent {...props} />;
  };
};

export default ToAppIfLoggedIn;
