import * as Yup from 'yup';
import {
  requiredArray,
  validPhone,
  validEmail,
} from '../../helpers/formSchemaHelper';

const BiobankConsentFormSchema = Yup.object().shape(
  {
    phone: Yup.string().when('email', {
      is: (value) => !value,
      then: Yup.string()
        .min(5, 'phone_too_short')
        .max(30, 'phone_too_long')
        .required('biobank_consent_form_error_message'),
      otherwise: validPhone,
    }),
    email: Yup.string().when('phone', {
      is: (value) => !value,
      then: Yup.string()
        .min(2, 'email_too_short')
        .email('invalid_email')
        .max(100, 'email_too_long')
        .required('biobank_consent_form_error_message'),
      otherwise: validEmail,
    }),
    biobanks: requiredArray,
  },
  [['phone', 'email']]
);

export default BiobankConsentFormSchema;
