/* eslint-disable react/no-danger */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { AppState, Survey } from '../typings/types';
import purify from '../helpers/domPurifierHelper';
import useUpdateMetadata from '../hooks/useUpdateMetadata';

type SurveyIntroProps = {
  survey: Survey;
};

const SurveyIntro: React.FC<SurveyIntroProps> = ({
  survey,
}: SurveyIntroProps) => {
  const { t } = useTranslation();
  const { uuid } = useSelector((state: AppState) => state.user.data);
  const updateMetadata = useUpdateMetadata();
  const cleanIntro = purify(survey.intro);
  const handleClick = (): void => {
    updateMetadata(survey.surveyid, uuid, { clicked_link: 1 });
  };

  const isSurvey = survey.type === 'answearable';
  const splitUrl = window.location.pathname.split('/');
  const studyId = splitUrl[2];
  const surveyPageURL = `/study/${studyId}/surveys`;

  const [page, setPage] = useState(0);
  const introPages = cleanIntro.split('<hr>');
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [page]);

  const isLastPage = page === introPages.length - 1;

  const Pager = () =>
    introPages.length > 1 ? (
      <p>
        {page + 1} / {introPages.length}
      </p>
    ) : null;

  const AbortButton = ({ children }) => (
    <div className="button-group">
      <Link
        className="button button-secondary button-wide button-link double-vertical-margin"
        to="/"
      >
        {t('survey_intro_abort')}
      </Link>
      {introPages.length > 1 && page > 0 && (
        <button
          type="button"
          className="button button-secondary button-wide button-link double-vertical-margin"
          onClick={() => setPage((prev) => (prev > 0 ? prev - 1 : 0))}
        >
          {t('survey_intro_prev_page')}
        </button>
      )}
      {children}
    </div>
  );

  const PagerButton = () => (
    <AbortButton>
      <button
        type="button"
        className="button button-wide button-link double-vertical-margin"
        onClick={() => setPage((prev) => prev + 1)}
      >
        {t('survey_intro_next_page')}
      </button>
    </AbortButton>
  );

  const SurveyButton = () => (
    <a
      className="button button-wide button-link double-vertical-margin"
      href={survey.survey_url || '#'}
      onClick={handleClick}
    >
      {t('survey_intro_start_survey')}
    </a>
  );

  const InfoFormButton = () => (
    <Link
      className="button button-wide button-link double-vertical-margin"
      to="form"
    >
      {t('survey_intro_start_info_form')}
    </Link>
  );

  const BackButton = () => (
    <Link className="link-grey" to={surveyPageURL}>
      {t('survey_intro_back')}
    </Link>
  );

  const ActionButton = () =>
    isSurvey ? (
      <SurveyButton />
    ) : (
      <AbortButton>
        <InfoFormButton />
      </AbortButton>
    );

  return (
    <div className="finbb-container">
      <Pager />
      <div className="wrap-words">
        <div dangerouslySetInnerHTML={{ __html: introPages[page] }} />
        {isLastPage ? <ActionButton /> : <PagerButton />}
        {isSurvey && <BackButton />}
      </div>
    </div>
  );
};

export default SurveyIntro;
