import PrivacyPolicy, {
  privacyPolicyUrl,
  privacyPolicyUrlPreLogin,
} from '../pages/static/PrivacyPolicy';

import AccessibilityPolicy2, {
  accessibilityPolicyUrl,
  accessibilityPolicyUrlPreLogin,
} from '../pages/static/AccessibilityPolicy';

import TermsAndConditions, {
  termsAndConditionsUrl,
  termsAndConditionsUrlPreLogin,
} from '../pages/static/TermsAndConditions';
import UnauthorizedUser from '../pages/static/UnauthorizedUser';

export default [
  {
    path: privacyPolicyUrl,
    private: false,
    exact: true,
    component: PrivacyPolicy,
  },
  {
    path: privacyPolicyUrlPreLogin,
    private: false,
    exact: true,
    component: PrivacyPolicy,
  },
  {
    path: accessibilityPolicyUrl,
    private: false,
    exact: true,
    component: AccessibilityPolicy2,
  },
  {
    path: accessibilityPolicyUrlPreLogin,
    private: false,
    exact: true,
    component: AccessibilityPolicy2,
  },
  {
    path: termsAndConditionsUrl,
    private: false,
    exact: true,
    component: TermsAndConditions,
  },
  {
    path: termsAndConditionsUrlPreLogin,
    private: false,
    exact: true,
    component: TermsAndConditions,
  },
  {
    path: '/portal',
    private: false,
    exact: true,
    component: UnauthorizedUser,
  },
];
