/* eslint-disable react/jsx-props-no-spreading */
import { useField } from 'formik';
import Form from 'react-bootstrap/Form';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ObjectSchema } from 'yup';
import { isRequiredField, existsInSchema } from '../helpers/formHelper';

type CustomFormControlProps = {
  name: string;
  label?: string;
  validationSchema?: ObjectSchema<any>;
  finalValidationSchema?: ObjectSchema<any> | null;
  enableValidation?: boolean;
  inputType?: 'select' | 'input';
  disabled?: boolean;
};

const CustomFormControl: React.FC<CustomFormControlProps> = ({
  label,
  validationSchema,
  finalValidationSchema = null,
  enableValidation = true,
  inputType = 'input',
  disabled = false,
  ...props
}: CustomFormControlProps) => {
  const { t } = useTranslation();
  const [field, meta] = useField(props);
  const { touched, error } = meta;
  const { name } = props;

  const schemaForRequiredLabel =
    finalValidationSchema !== null ? finalValidationSchema : validationSchema;

  const isRequiredLabel = useMemo(
    () => isRequiredField(schemaForRequiredLabel, name),
    [schemaForRequiredLabel, name]
  );
  const isRequiredHtml5 = useMemo(
    () => isRequiredField(validationSchema, name),
    [validationSchema, name]
  );

  const itExistsInSchema = useMemo(
    () => existsInSchema(validationSchema, name),
    [validationSchema, name]
  );

  return (
    <>
      {label && (
        <Form.Label>
          {label} {isRequiredLabel ? '*' : ''}
        </Form.Label>
      )}
      <Form.Control
        {...field}
        {...props}
        isInvalid={enableValidation && itExistsInSchema && touched && !!error}
        isValid={enableValidation && itExistsInSchema && touched && !error}
        required={isRequiredHtml5}
        disabled={disabled}
        as={inputType}
      />
      {touched && error && error !== 'biobank_consent_form_error_message' && (
        <Form.Control.Feedback type="invalid">{t(error)}</Form.Control.Feedback>
      )}
    </>
  );
};

export default CustomFormControl;
