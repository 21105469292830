import Cookies from 'js-cookie';
import { decode } from 'jsonwebtoken';
import config from '../config';

const cookieName = '__bbp_access_token';

export const getToken = (): string | undefined => {
  return Cookies.get(cookieName);
};

export const setToken = (token: string): void => {
  Cookies.set(cookieName, token, {
    domain: config.cookieDomain,
    path: config.cookiePath,
    secure: parseInt(config.secureCookies, 10) === 1,
  });
};

export const verifyAccessToken = (token: string): boolean => {
  try {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const tokenData: any = decode(token);
    const numericDateNow = Math.floor(Date.now() / 1000);
    const isTokenExpired = parseInt(tokenData.exp, 10) < numericDateNow;
    if (tokenData && tokenData.uuid && tokenData.name && !isTokenExpired) {
      return true;
    }
    return false;
  } catch (e) {
    return false;
  }
};

export const isAuthenticated = (): boolean => {
  if (parseInt(config.enableJwtAuth, 10) === 1) {
    const token = getToken();
    return Boolean(token && token.length && verifyAccessToken(token));
  }
  return true;
};

export const removeToken = (): void => {
  Cookies.remove(cookieName, {
    domain: config.cookieDomain,
    path: config.cookiePath,
    secure: parseInt(config.secureCookies, 10) === 1,
  });
};

export const redirectToLogin = (path = '/login'): void => {
  window.location.href = path;
};
