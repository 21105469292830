import * as Yup from 'yup';

export const validEmail = Yup.string()
  .min(2, 'email_too_short')
  .email('invalid_email')
  .max(100, 'email_too_long');

export const validPhone = Yup.string()
  .min(5, 'phone_too_short')
  .max(30, 'phone_too_long');

export const biobanks = Yup.array().min(1);

export const requiredArray = Yup.array().required().min(1);
