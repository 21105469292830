import config from '../config';
import useFetch from './useFetch';

const useFetchStudies = () => {
  const { data, loading, error } = useFetch(`${config.surveyApiUrl}/study`);

  return {
    studies: data,
    loading,
    error,
  };
};

export default useFetchStudies;
