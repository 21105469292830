import React from 'react';
import { useTranslation } from 'react-i18next';
import EditProfileForm from '../components/EditProfileForm/EditProfileForm';
import MainContainer from '../components/MainContainer';
import Title from '../components/Title/Title';

const EditProfilePage: React.FC = () => {
  const { t } = useTranslation();
  return (
    <MainContainer>
      <Title>{t('edit_profile_header')}</Title>
      <div className="finbb-container">
        <div className="narrow-container">
          <EditProfileForm />
        </div>
      </div>
    </MainContainer>
  );
};

export default EditProfilePage;
