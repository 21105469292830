import CHANGE_LANGUAGE from './i18n.actions';
import i18n from '../i18n';

export const doChangeLanguage = (lng: any) => {
  return {
    type: CHANGE_LANGUAGE,
    lng,
  };
};

export const changeLanguage = (lng: any) => {
  return (dispatch: any) => {
    dispatch(doChangeLanguage(lng));
    i18n.changeLanguage(lng);
  };
};
