/* eslint-disable camelcase */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import Card from 'react-bootstrap/esm/Card';
import { format, parse } from 'date-fns';
import useFetchSurveys from '../../hooks/useFetchSurveys';
import LoadingWrapper from '../LoadingWrapper';
import LoadingIndicator from '../LoadingIndicator';
import styles from './SurveyList.module.scss';
import HorizontalLine from '../HorizontalLine/HorizontalLine';
import EmptyListPlaceholder from '../EmptyListPlaceholder';

type Survey = {
  title: string;
  surveyid: string;
  description: string;
  study_title: string;
  studyid: string;
  end_date: string;
  participated: string;
  clicked_link: string;
};

type SurveyProps = {
  survey: Survey;
};

type SurveysProps = {
  surveys: Survey[] | null;
  placeholderText: string;
};

const Survey: React.FC<SurveyProps> = ({ survey }: SurveyProps) => {
  const { t } = useTranslation();

  const parsedEndDate = parse(
    survey.end_date,
    'yyyy-MM-dd HH:mm:ss',
    new Date()
  );
  const formattedEndDate = format(parsedEndDate, 'd.M.yyyy');

  return (
    <Card className={styles.card} style={{ width: '25em' }}>
      <Card.Body>
        <Card.Title>{survey.title}</Card.Title>
        <div className={styles.surveyDeadline}>{`${t(
          'survey_deadline'
        )} ${formattedEndDate}`}</div>
        <HorizontalLine className="top-margin" />
        <Card.Text>{survey.description}</Card.Text>
      </Card.Body>
      {survey.participated === '0' ? (
        <Card.Footer className={styles.cardFooter}>
          <Link
            className="button button-block button-link"
            to={`/study/${survey.studyid}/survey/${survey.surveyid}/introduction`}
          >
            {t('studylist_start_study')}
          </Link>
        </Card.Footer>
      ) : null}
    </Card>
  );
};

const Surveys: React.FC<SurveysProps> = ({
  surveys,
  placeholderText,
}: SurveysProps) => {
  return surveys && surveys.length > 0 ? (
    <div className={styles.container}>
      {surveys.map((survey: Survey) => (
        <Survey key={survey.surveyid} survey={survey} />
      ))}
    </div>
  ) : (
    <EmptyListPlaceholder title={placeholderText} />
  );
};

const SurveyList: React.FC = () => {
  const { t } = useTranslation();
  const [tabIndex, setTabIndex] = useState(0);
  const { surveys, loading, error } = useFetchSurveys();
  const activeSurveys = surveys?.filter(
    (survey) => survey.participated === '0' && survey.expired === '0'
  );
  const completedSurveys = surveys?.filter(
    (survey) => survey.participated === '1' || survey.expired === '1'
  );
  return (
    <>
      <Tabs
        selectedTabClassName={styles.tabSelected}
        defaultIndex={tabIndex}
        onSelect={(index: number): void => setTabIndex(index)}
      >
        <div className="finbb-container">
          <TabList className={styles.tabList}>
            <Tab className={styles.tab}>{t('surveys_active')}</Tab>
            <Tab className={styles.tab}>{t('surveys_completed')}</Tab>
          </TabList>
        </div>
        <div className={styles.horizontalLineBelowTabList} />
        <div className="finbb-container">
          <TabPanel className={styles.tabPanel}>
            <LoadingWrapper
              isLoading={loading}
              isInErrorState={error != null}
              errorMsg={t('surveys_loading_error')}
              loadingComponent={<LoadingIndicator />}
            >
              <Surveys
                surveys={activeSurveys}
                placeholderText={t('surveys_no_active_surveys')}
              />
            </LoadingWrapper>
          </TabPanel>
          <TabPanel>
            <LoadingWrapper
              isLoading={loading}
              isInErrorState={error !== null}
              errorMsg={t('surveys_loading_error')}
              loadingComponent={<LoadingIndicator />}
            >
              <Surveys
                surveys={completedSurveys}
                placeholderText={t('surveys_no_completed_surveys')}
              />
            </LoadingWrapper>
          </TabPanel>
        </div>
      </Tabs>
    </>
  );
};
export default SurveyList;
