import React from 'react';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { hasUserGivenConsent } from '../helpers/consentedBiobanksHelper';
import { AppState } from '../typings/types';
import LoadingWrapper from './LoadingWrapper';
import useUser from '../hooks/useUser';
import useFetchStudies from '../hooks/useFetchStudies';
import CustomSpinner from './CustomSpinner/CustomSpinner';

const isReadonly = (studies: { type: 'answearable' | 'readonly' }[]): boolean =>
  !studies.some((study) => study.type === 'answearable');

const RedirectBasedOnConsent: React.FC = () => {
  const { uuid } = useSelector((state: AppState) => state.user.data);
  const { data, loading, error } = useUser(uuid);
  const {
    studies,
    loading: studiesLoading,
    error: studiesError,
  } = useFetchStudies();
  const isThereConsent = hasUserGivenConsent(data?.biobanks);
  const route =
    isReadonly(studies || []) || isThereConsent ? '/studies' : '/consent';
  const { t } = useTranslation();

  return (
    <LoadingWrapper
      isLoading={loading || studiesLoading}
      isInErrorState={error != null || studiesError != null}
      errorMsg={t('unknown_error')}
      loadingComponent={<CustomSpinner />}
    >
      <Redirect to={route} />
    </LoadingWrapper>
  );
};

export default RedirectBasedOnConsent;
