import React from 'react';
import styles from './UserHeader.module.scss';

type UserHeaderProps = {
  title: string;
};
const UserHeader = ({ title }: UserHeaderProps) => {
  return (
    <header className={styles.userHeader}>
      <h1>{title}</h1>
    </header>
  );
};

export default UserHeader;
