import Container from 'react-bootstrap/Container';
import { Code as Loading } from 'react-content-loader';
import React from 'react';
import ErrorBox from './ErrorBox';

type LoadingProps = {
  isLoading?: boolean;
  isInErrorState?: boolean;
  errorMsg: any;
  children: any;
  loadingComponent?: any;
};

const LoadingWrapper = ({
  isLoading,
  isInErrorState,
  errorMsg,
  children,
  loadingComponent,
}: LoadingProps) => {
  if (isLoading && !isInErrorState) {
    return loadingComponent || <Loading />;
  }
  if (isInErrorState) {
    return (
      <Container>
        <ErrorBox msg={errorMsg} />
      </Container>
    );
  }
  return children;
};

export default LoadingWrapper;
