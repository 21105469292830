import React from 'react';
import styles from './HorizontalLine.module.scss';

type HorizontalLineProps = {
  className: string;
};

const HorizontalLine = ({ className }: HorizontalLineProps) => (
  <div className={`${styles.horizontalLine} ${className}`} />
);

export default HorizontalLine;
