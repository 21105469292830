/* eslint-disable no-param-reassign */
// source: https://github.com/jaredpalmer/formik/issues/1728
import { getIn } from 'formik';

export const isRequiredInValidationSchema = (
  validationSchema,
  fieldName
): boolean => {
  const schema = fieldName.split('.').reduce((acc, field) => {
    const tests = getIn(acc.fields, field);
    if (tests) acc = tests;
    return acc;
  }, validationSchema.describe());

  if (schema && schema.tests)
    return !!schema.tests.find(({ name }) => name === 'required');

  return false;
};

export const isRequiredField = (validationSchema, fieldName): boolean =>
  validationSchema
    ? isRequiredInValidationSchema(validationSchema, fieldName)
    : false;

export const existsInSchema = (validationSchema, fieldName): boolean => {
  return validationSchema && validationSchema.fields[fieldName];
};

export const nullsToEmptyStrings = (formObj) =>
  Object.entries(formObj).reduce((result, [key, value]) => {
    result[key] = value || '';
    return result;
  }, {});
