import config from '../config';
import useFetch from './useFetch';

const useUser = (userId: string) => {
  const { data, loading, error } = useFetch(
    `${config.surveyApiUrl}/subject/${userId}`
  );

  return {
    data,
    loading,
    error,
  };
};

export default useUser;
