/* eslint-disable import/prefer-default-export */
import {
  USER_LOGIN,
  USER_LOGIN_FAILURE,
  USER_LOGIN_SUCCESSFUL,
  USER_RESET_FORM_STATE,
  USER_UPDATE_PROFILE,
  USER_UPDATE_PROFILE_FAILURE,
  USER_UPDATE_PROFILE_SUCCESSFUL,
} from '../actions/users.actions';

const initialState = {
  login: {
    errorCode: null,
    errorMessage: null,
    success: false,
  },
  updateProfile: {
    errorCode: null,
    errorMessage: null,
    success: false,
  },
};

const appendToState = (key, errorCode, errorMessage, success) => ({
  ...initialState,
  [key]: {
    errorCode,
    errorMessage,
    success,
  },
});

const reduceSuccess = (key) => appendToState(key, null, null, true);

const reduceFailure = (key, action) =>
  appendToState(key, action.code, action.message, false);

const formsReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_LOGIN:
    case USER_LOGIN_FAILURE:
      return reduceFailure('login', action);
    case USER_LOGIN_SUCCESSFUL:
      return reduceSuccess('login');
    case USER_UPDATE_PROFILE:
    case USER_UPDATE_PROFILE_FAILURE:
      return reduceFailure('updateProfile', action);
    case USER_UPDATE_PROFILE_SUCCESSFUL:
      return reduceSuccess('updateProfile');
    case USER_RESET_FORM_STATE:
      return initialState;
    default:
      return state;
  }
};

export default formsReducer;
