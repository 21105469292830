import config from '../config';
import useFetch from './useFetch';

const useFetchStudy = (id: string) => {
  const { data, loading, error } = useFetch(
    `${config.surveyApiUrl}/study/${id}`
  );

  return {
    study: data,
    loading,
    error,
  };
};

export default useFetchStudy;
