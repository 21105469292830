import React from 'react';
import { useTranslation } from 'react-i18next';
import StudyList from '../components/StudyList/StudyList';
import Title from '../components/Title/Title';
import MainContainer from '../components/MainContainer';

const StudyListPage = () => {
  const { t } = useTranslation();

  return (
    <MainContainer>
      <Title>{t('studylist_studies')}</Title>
      <StudyList />
    </MainContainer>
  );
};

export default StudyListPage;
