import React from 'react';
import { useTranslation } from 'react-i18next';
import MainContainer from '../../components/MainContainer';
import Title from '../../components/Title/Title';

const UnauthorizedUser: React.FC = () => {
  const { t } = useTranslation();
  return (
    <MainContainer>
      <Title>{t('unauthorized_user_title')}</Title>
      <div className="finbb-container">
        <div className="narrow-container">
          <p>{t('unauthorized_user_text')}</p>
        </div>
      </div>
    </MainContainer>
  );
};

export default UnauthorizedUser;
