import React from 'react';
import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import LanguageChangeControlContainer from './LanguageChangeControl/LanguageChangeControlContainer';
import NavigationUser from './NavigationUser/NavigationUser';

const Header: React.FC = ({ children }) => {
  const { pathname } = useLocation();
  const { t } = useTranslation();

  const pathsWithoutNavUser = [
    '/login',
    '/logout',
    '/failedauth',
    '/portal',
    '/terms-and-conditions',
    '/privacy-policy',
    '/accessibility-policy',
  ];
  return (
    <header className="header collapsed">
      <div className="finbb-container">
        <div className="nav-container">
          <nav className="primary">
            <a href="/studies" className="logo hide-text">
              {t('biobank_portal')}
            </a>
          </nav>
          <nav className="secondary">
            <LanguageChangeControlContainer className="nav-link" />
            {!pathsWithoutNavUser.includes(pathname) ? (
              <NavigationUser className="nav-link" />
            ) : null}
          </nav>
        </div>
        {children}
      </div>
    </header>
  );
};

export default Header;
