/* eslint-disable react/no-danger */
import React from 'react';
import { useTranslation } from 'react-i18next';
import flag from '../../assets/img/avainlippu.svg';
import { privacyPolicyUrlPreLogin } from '../../pages/static/PrivacyPolicy';
import { accessibilityPolicyUrlPreLogin } from '../../pages/static/AccessibilityPolicy';
import { termsAndConditionsUrlPreLogin } from '../../pages/static/TermsAndConditions';

const Footer = () => {
  const { t } = useTranslation();

  return (
    <div className="footer-wrapper">
      <footer>
        <div className="finbb-container">
          <div className="footer-row">
            <div className="primary">
              <p className="footer-desc">{t('footer_desc')}</p>
            </div>
            <div className="secondary">
              <p>
                <img
                  className="avainlippu"
                  src={flag}
                  alt={t('made_in_finland')}
                />
              </p>
            </div>
          </div>
          <div className="footer-row no-flex">
            <div className="secondary">
              <p className="footer-copy">{t('footer_copyright')}</p>
              <p
                className="footer-privacy"
                dangerouslySetInnerHTML={{
                  __html:
                    t('footer_privacy', {
                      privacyUrl: privacyPolicyUrlPreLogin,
                    }) +
                    t('footer_accessibility', {
                      accessibilityUrl: accessibilityPolicyUrlPreLogin,
                    }) +
                    t('footer_terms', {
                      termsUrl: termsAndConditionsUrlPreLogin,
                    }),
                }}
              />
            </div>
            <div className="secondary">{t('footer_address')}</div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
