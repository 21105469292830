import DOMPurify from 'dompurify';

const allowedTags = [
  'h1',
  'h2',
  'h3',
  'a',
  'b',
  'strong',
  'i',
  'em',
  'p',
  'br',
  's',
  'ss',
  'a',
  'ol',
  'ul',
  'li',
  'img',
  'hr',
];

// Add a hook to make all links open a new window
DOMPurify.addHook('afterSanitizeAttributes', (node) => {
  // set all elements owning target to target=_blank
  if ('target' in node) {
    node.setAttribute('target', '_blank');
    node.setAttribute('rel', 'noopener');
  }
});

const purify = (value) => {
  const cleanValue = DOMPurify.sanitize(value, {
    ALLOWED_TAGS: allowedTags,
    ALLOWED_ATTR: ['href', 'src', 'alt', 'width', 'height'],
  });
  return cleanValue;
};

export default purify;
