import config from '../config';
import axios from '../helpers/axios';
import logError from '../helpers/logger';

type ContactInformation = {
  name: string;
  phone: string;
  lang: string;
};

const useSubmitContactInformation = (userId: string, surveyId: string) => {
  const submit = async (
    data: ContactInformation,
    onSuccess?: () => void,
    onError?: () => void
  ): Promise<void> => {
    const { name, phone, lang } = data;
    try {
      const response = await axios.post(
        `${config.surveyApiUrl}/survey/${surveyId}/subject/${userId}/contact`,
        { name, phone, lang },
        {
          withCredentials: true,
        }
      );
      if (response.status === 200) {
        onSuccess?.();
      }
    } catch (err: any) {
      logError(err);
      onError?.();
    }
  };

  return {
    submit,
  };
};

export default useSubmitContactInformation;
