import Alert from 'react-bootstrap/Alert';
import React from 'react';

type ErrorBoxProps = {
  msg: string;
  className?: string;
};

const ErrorBox: React.FC<ErrorBoxProps> = ({ msg, className }) => (
  <Alert className={className} variant="danger">
    {msg}
  </Alert>
);

export default ErrorBox;
