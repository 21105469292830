/* eslint-disable no-alert */
import i18next from 'i18next';
import { AxiosError } from 'axios';
import axios from './axios';
import logError from './logger';

export const handleError = (error: AxiosError, errorMsgPrefix = 'Error') => {
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    alert(`${errorMsgPrefix}: ${JSON.stringify(error.response)}`);
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    alert(`${errorMsgPrefix}: ${JSON.stringify(error.request)}`);
  } else {
    // Something happened in setting up the request that triggered an Error
    alert(`${errorMsgPrefix}: ${error.message}`);
  }
  logError(error);
};

export const toFormData = (values: any, fieldsToNotFlatten: any[] = []) => {
  const formData = new FormData();

  Object.keys(values).forEach((key) => {
    const value = values[key] || '';

    if (Array.isArray(value)) {
      if (value[0] instanceof File) {
        value.forEach((arrValue) => {
          formData.append(`${key}[]`, arrValue);
        });
      } else if (fieldsToNotFlatten.includes(key)) {
        value.forEach((arrValue) => {
          formData.append(`${key}[]`, JSON.stringify(arrValue));
        });
      } else {
        formData.append(key, value.join(' '));
      }
    } else {
      formData.append(key, value);
    }
  });

  return formData;
};

export const postMultipartFormData = async (
  url: string,
  data: any,
  fieldsToNotFlatten: any[]
) =>
  axios.post(url, toFormData(data, fieldsToNotFlatten), {
    headers: {
      'content-type': `multipart/form-data`,
    },
  });

export const toError = (errorObj: AxiosError) => ({
  error: errorObj.response
    ? errorObj.response.data.message
    : i18next.t('unknown_error'),
});
