/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import logout from '../actions/root';
import CustomSpinner from '../components/CustomSpinner/CustomSpinner';
import { AppState } from '../typings/types';

const LogoutPage = () => {
  const dispatch = useDispatch();
  const { uuid } = useSelector((state: AppState) => state.user.data);
  useEffect(() => {
    dispatch(logout(uuid));
  }, []);

  return <CustomSpinner />;
};

export default LogoutPage;
