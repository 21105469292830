import React from 'react';
import InfoBlock from './InfoBlock/InfoBlock';
import survey from '../assets/img/survey.svg';

const EmptyListPlaceholder: React.FC<{ title: string }> = ({ title }) => {
  return (
    <InfoBlock title={title} iconSrc={survey} description="" button={null} />
  );
};

export default EmptyListPlaceholder;
